<template>
  <div class="box">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <slot></slot>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  props: {
    // interFaceFunc: {
    //   type: Function,
    //   require: true
    // },

    list: {
      type: Array,
      require: true
    },

    requestData: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,

      componentList: []
    };
  },

  watch: {
    requestData(newV) {
      if (newV) {
        let data = newV;

        console.log(data);

        data.rows.forEach(item => {
          this.componentList.push(item);
        });

        this.$emit("update:list", this.componentList);

        this.total = data.total;
        this.pageNum++;
        this.loading = false;

        if (this.list.length >= data.total) {
          this.finished = true;
        }
      }
    }
  },

  methods: {
    onLoad() {
      if (this.refreshing) {
        this.componentList = [];
        this.$emit("update:list", this.componentList);
        this.total = 0;
        this.pageNum = 1;
        this.refreshing = false;
      }

      this.$emit("onRequest", this.pageNum);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
}
</style>
