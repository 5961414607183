<template>
  <!-- 优惠券页面 -->
  <div class="coupons-wrapper">
    <div class="titleBar" v-if="isApp"></div>
    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>场内优惠券</span>
      </div>
    </div>

    <div class="coupons-content">
      <div class="coupons-list">
        <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        > -->
        <refresh-list
          :list.sync="list"
          :requestData="requestData"
          @onRequest="onRequest"
        >
          <div class="coupons-item" v-for="(item, index) in list" :key="index">
            <div class="item-info">
              <div class="info-left">
                <div class="info-ticketValue">
                  {{ item.ticketValue }}
                  <span>{{ item.type === 1 ? "元" : "%" }}</span>
                </div>
                <div class="info-condValidate">
                  {{ item.condValidate === 0 ? "在场内验证" : "入场前验证" }}
                </div>
              </div>
              <div class="info-right">
                <div class="info-coupons-name">
                  {{ item.name }}
                </div>
                <div class="info-parking-name">
                  {{ item.parkingGarageName }}
                </div>
              </div>
            </div>
            <!-- <div class="item-num">123 / 123</div> -->
            <div class="item-note">
              <div class="note-txt">
                <div class="qrcode-btn" @click="onShowQrcode(item)">二维码</div>
                <div class="record-btn" @click="gotoRecord(item)">领券记录</div>
              </div>
              <div class="coupons-num">
                {{ item.remainQty }} / {{ item.totalQty }}
              </div>
            </div>
          </div>
        </refresh-list>

        <!-- </van-list>
      </van-pull-refresh> -->
      </div>
    </div>

    <transition name="slide">
      <router-view></router-view>
    </transition>

    <!-- 二维码弹出层 -->
    <van-popup
      v-model="showQrcode"
      round
      position="bottom"
      style="height: 70%; padding-top: 4px;"
    >
      <div class="pop-box">
        <div class="pop-title">
          <div class="pop-coupons-name">{{ popObject.name }}</div>
          <div class="pop-coupons-qrcode" @click="gotoRecord(popObject)">
            领券记录
          </div>
        </div>
        <div class="pop-qrcode" :class="{ filter: loadingQRcode }">
          <div class="qrcode-box">
            <div
              class="qrcode"
              ref="qrcode"
              id="qrcode"
              style="opacity : 0 ;"
            ></div>

            <img class="qrcode-img" :src="qrcodeSrc" alt="" />
          </div>
          <div class="qrcode-time">
            <p v-if="expireTime > 0">{{ expireTime | mmssTimeFilter }}</p>
            <p v-else>5分00秒</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
import QRCode from "qrcodejs2";
import { mapState } from "vuex";
import RefreshList from "../components/RefreshList.vue";

export default {
  data: () => ({
    list: [],
    // refreshing: false,
    // finished: false,
    // loading: false,
    // total: 0,
    // pageNum: 1,

    showQrcode: false,
    // showList: false,
    // popTabActive: 0,

    popObject: {},
    // popList: [],
    // popRefreshing: false,
    // popFinished: false,
    // popLoading: false,
    // popTotal: 0,
    // popPageNum: 1,

    requestData: {},
    // popRequestData: {},

    qrcodeData: {},
    expireTime: 0,
    loadingQRcode: false,
    qrcodeSrc: ""
  }),
  watch: {
    showQrcode(newV) {
      if (!newV) {
        this.clearPageTimer();
      }
    }
  },
  components: {
    RefreshList
  },

  computed: {
    ...mapState({
      isApp: state => state.isApp
    })

    // showPopList() {
    //   return this.popList.filter(item => item.used == this.popTabActive);
    // }
  },

  methods: {
    gotoRecord(item) {
      this.showQrcode = false;
      this.$router.push({
        name: "CouponsRecord",
        params: { id: item.id, couponsName: item.name }
      });
    },

    onShowQrcode(popObject) {
      this.popObject = popObject;
      this.showQrcode = true;

      this.getQRcodeData(popObject.id);
    },

    async getQRcodeData(id) {
      this.loadingQRcode = true;

      let { data } = await this.$api.getCouponsToken(id);
      if (data) {
        console.log(data);
        this.qrcodeData = data;
        this.refreshQrCodeStatus(data.token, () => {
          this.getQRcodeData(id);
        });
        this.setCountDownTimer(data.expireTimeStr, () => {
          this.getQRcodeData(id);
        });

        let testUrl = "http://172.18.10.73:8979/#/coupons/"; //测试地址
        if (process.env.NODE_ENV === "production") {
          this.createQrcode(`${data.url}${data.token}`);
          console.log(`${data.url}${data.token}`);
        } else {
          this.createQrcode(`${testUrl}${data.token}`);
          console.log(`${testUrl}${data.token}`);
        }
      }
    },

    refreshQrCodeStatus(token, callback) {
      this.$api
        .refreshQrCodeStatus(token)
        .then(({ data }) => {
          // console.log("当前二维码状态 ==>>>", data);
          if (data) {
            this.clearPageTimer();
            callback && callback();
          }
          if (this.qrcodeStatusTimer) {
            clearInterval(this.qrcodeStatusTimer);
          }
          this.qrcodeStatusTimer = setTimeout(() => {
            this.refreshQrCodeStatus(token, callback);
          }, 1000);
        })
        .catch(() => {
          this.clearPageTimer();
        });
    },

    setCountDownTimer(expireTimeStr, callback) {
      let expireTime = new Date(
        moment(expireTimeStr, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
      ).getTime();
      console.log(
        new Date(
          moment(expireTimeStr, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")
        ),
        new Date()
      );
      this.expireTimeStr = expireTime - new Date().getTime();
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
      }
      this.countDownTimer = setInterval(() => {
        this.expireTime = expireTime - new Date().getTime();

        if (this.expireTime <= 1) {
          this.clearPageTimer();
          callback && callback();
        }
      }, 1000);
    },

    clearPageTimer() {
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
      }

      if (this.qrcodeStatusTimer) {
        clearInterval(this.qrcodeStatusTimer);
      }
    },

    createQrcode(content) {
      console.log(
        this.$refs.qrcode.offsetWidth,
        this.$refs.qrcode.offsetHeight
      );
      let width = this.$refs.qrcode.offsetWidth;
      let height = this.$refs.qrcode.offsetHeight;
      this.$refs.qrcode.innerHTML = "";
      this.qrcodeObj = new QRCode("qrcode", {
        width, // 设置宽度，单位像素
        height, // 设置高度，单位像素
        text: content, // 设置二维码内容或跳转地址
        correctLevel: QRCode.CorrectLevel.Q
      });

      let myCanvas = document.getElementsByTagName("canvas")[0];
      this.qrcodeSrc = myCanvas.toDataURL("image/png");
      this.loadingQRcode = false;
    },

    async onRequest(pageNum) {
      let { data } = await this.$api.getCouponsList(pageNum);
      this.requestData = data;
    }
  },

  filters: {
    mmssTimeFilter(count) {
      let sum = Math.ceil(count / 1000);
      let m = Math.floor(sum / 60);
      let mStr = m > 0 ? m + "分" : "";
      let s = Math.floor(sum % 60);

      return `${mStr}${s < 10 ? "0" + s : s}秒`;
    }
  },

  mounted() {},
  beforeDestroy() {
    this.clearPageTimer();
  }
};
</script>

<style lang="less" scoped>
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.coupons-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.coupons-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.coupons-list {
  flex: 1;
  padding: 10px 20px;
  overflow: scroll;
  background-color: #edf0f7;
  width: calc(100% + 20px);
  // padding: 10px calc(10% + 10px);
  transform: translate(-10px);
}

.coupons-item {
  padding: 12px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
}

.item-info {
  display: flex;
  margin-bottom: 8px;
}

.info-left {
  color: #ee0a24;
  padding: 0 16px;
  text-align: center;

  .info-ticketValue {
    padding: 0 6px;
    margin-bottom: 6px;
    font-size: 30px;

    span {
      font-size: 14px;
    }
  }

  .info-condValidate {
    font-size: 14px;
  }
}

.info-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 6px 0;

  .info-coupons-name {
    font-size: 16px;
    color: #333;
    font-weight: 700;
    margin-bottom: 6px;
  }

  .info-parking-name {
    font-size: 14px;
    color: #000;
  }
}

.item-num {
  font-size: 14px;
  text-align: right;
}

.item-note {
  width: 100%;
  border-top: 1px dashed #ebedf0;
  padding-top: 8px;
  padding-left: 6px;
  font-size: 14px;
  display: flex;
  align-items: center;

  .note-txt {
    flex: 1;
    display: flex;

    > div {
      padding: 8px 20px;
      border-radius: 20px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .qrcode-btn {
      background-color: #03a89e;
      color: #fff;
      margin-right: 10px;
    }

    .record-btn {
      color: #fff;
      background-color: #1989fa;
    }
  }

  .coupons-num {
    font-size: 16px;
  }
}

.pop-box {
  height: 100%;
  // padding: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pop-title {
  padding: 10px 20px;
  font-size: 18px;
  display: flex;
  align-items: center;

  .pop-coupons-name {
    flex: 1;
  }

  .pop-coupons-qrcode {
    margin-left: 10px;
    padding: 6px 14px;
    color: #fff;
    background-color: #1989fa;
    font-size: 14px;
    border-radius: 20px;
  }
}

.pop-tab {
  box-shadow: 0 6px 12px -12px #969799;
}

.pop-content {
  width: 100%;
  flex: 1;
  background-color: #f7f8fa;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 12px;
}

.pop-qrcode {
  flex: 1;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #f7f8fa;
  overflow: hidden;

  .qrcode-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    padding: 10%;
    overflow: hidden;
    position: relative;

    // background-color: #333;

    .qrcode {
      width: 220px;
      height: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .qrcode-img {
      position: absolute;
      width: 220px;
      height: 220px;
      top: 0;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .qrcode-time {
    width: 100%;
    flex: 0 0 50px;
    height: 50px;
    padding: 10px 0;
    font-size: 24px;
    text-align: center;
  }
}

.filter {
  filter: blur(10px);
}
</style>
